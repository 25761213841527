import React from 'react';
import logo from './logo.svg';
import './App.css';
import version from './_generated/version';

function App() {

    let barcodeIsAvailable = false;

    if ('BarcodeDetector' in window) {
        barcodeIsAvailable = true;
    } else {
        barcodeIsAvailable = false;
    }

    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo"/>
                <p>
                    Edit <code>src/App.tsx</code> and save to reload.
                </p>
                <a
                    className="App-link"
                    href="https://reactjs.org"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Version {version}, {barcodeIsAvailable ? "Barcode scanner is available" : "NO"}
                </a>
            </header>
        </div>
    );
}

export default App;
